

@-webkit-keyframes opening_bg {
  0% {opacity: 1;}
  60% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes opening_bg {
  0% {opacity: 1;}
  60% {opacity: 1;}
  100% {opacity: 0;}
}

@-webkit-keyframes opening_logo {
  0% {width:300px;}
  100% {width:140%;}
}
@keyframes opening_logo {
  0% {width:300px;}
  100% {width:140%;}
}

.topvisual {
  margin-top: 94px;
  width: 100%;
  height: 584px;
  background: #f9f8f2 url(../img/top/main.jpg) top center no-repeat;
  position: relative;
  z-index: 9;
}

.topvisual .main{
  position: absolute;
  top: 0;
  left: 50%;
  width: 486px;
  margin-left: -420px;
  text-align: center;
  padding: 180px 0;
}

.topvisual .main .catch{
  font-size: 28px;
  font-weight: 700;
  line-height: 1.8;
}

.topvisual .main .name{
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
}

.topvisual .main .name span{
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
}

.topvisual .main .name img{
  height: 42px;
}


@media screen and (max-width: 760px) {

.topvisual {
  margin-top: 72px;
  width: 100%;
  height: auto;
  padding-bottom: 140%;
  background: #f9f8f2 url(../img/top/main_sp.jpg) bottom center no-repeat;
  background-size: 100% auto;
}

.topvisual .main{
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  margin-left: 0;
  padding: 0;
}

.topvisual .main .catch{
  font-size: 20px;
  line-height: 1.6;
}

.topvisual .main .name{
  font-size: 12px;
  margin-top: 2%;
}

.topvisual .main .name span{
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

.topvisual .main .name img{
  width: auto;
  height: 34px;
  margin-top: -4px;
}

}


/* title_box */
.section .title_box {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
}

.section .title_box h3 {
  padding-top: 74px;
  position: relative;
  font-size: 76px;
  display: block;
  line-height: .6;
  color: #FFF;
  text-shadow:
             #291615 1px 1px 0, #291615 -1px -1px 0,/*右下、左上*/
             #291615 -1px 1px 0, #291615 1px -1px 0,/*右上、左下*/
             #291615 0px 1px 0, #291615  0 -1px 0,/*右、左*/
             #291615 -1px 0 0, #291615 1px 0 0;/*上、下*/
}

.section .title_box h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 30px;
  background: url(../img/cmn/mind.svg) top center no-repeat;
  background-size: auto 100%;
}

.section .title_box .stit {
  font-weight: 500;
}



@media screen and (max-width: 761px) {
.section .title_box {
  margin-bottom: 5%;
  font-size: 13px;
}

.section .title_box h3 {
  font-size: 42px;
  padding-top: 54px;
}

}


/* section_news*/
.section_news {
  width: 870px;
  margin: -68px auto 64px;
  background: #FFF;
  position: relative;
  z-index: 99;
  padding: 40px 54px 32px;
}

.section_news .title_box {
  margin-bottom: 5px;
}

.section_news .title_box h4 {
  font-size: 30px;
  float: left;
}

.section_news .title_box h4 .sub {
  font-size: 12px;
  padding-left: 10px;
  font-weight: 300;
}

.section_news .title_box .btn_list {
  float: right;
}

.section_news .title_box .btn_list a {
  font-size: 13px;
  font-weight: 500;
  padding-left: 20px;
  background: url(../img/cmn/icon_list.svg) 0 0 no-repeat;
  background-size: 12px auto;
}

.section_news .title_box .news_list {
}

.section_news .news_list li a {
  font-weight: 500;
  padding: 6px 0;
}

.section_news .news_list li p.date {
  display: inline-block;
  vertical-align: middle;
}

.section_news .news_list li ul.icons {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.section_news .news_list li ul.icons li {
  display: none;
}

.section_news .news_list li ul.icons li:first-child {
  display: block;
}

.section_news .news_list li p.tit {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  width: 472px;
  height: 26px;
  overflow: hidden;
}


@media screen and (max-width: 761px) {
.section_news {
  width: 94%;
  margin: -8% auto 15%;
  padding: 7%;
}

.section_news .title_box h4 {
  font-size: 24px;
}

.section_news .title_box h4 .sub {
  font-size: 11px;
  padding-left: 6px;
}

.section_news .title_box .btn_list a {
  font-size: 12px;
}

.section_news .title_box .news_list {
}

.section_news .news_list li a {
  padding: 6px 0 0;
}

.section_news .news_list li ul.icons {
  margin: 0 3px;
}

.section_news .news_list li ul.icons li:first-child {
  display: block;
}

.section_news .news_list li p.tit {
  display: block;
  font-size: 14px;
  width: 100%;
  height: 26px;
}


}


/* section_mind */
.section_mind {
  width: 100%;
  height: 580px;
  background: #f2dad6 url(../img/top/bg_mind.jpg) top center no-repeat;
  padding: 90px 0 125px;
}

.section_mind .info_box {
  width: 1050px;
  margin: -55px auto 30px;
  background: #FFF;
  font-weight: 600;
  text-align: center;
  padding: 60px 0 50px;
}

.section_mind .info_box .catch {
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
}

.section_mind .info_box .txt {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  line-height: 2;
}


@media screen and (max-width: 761px) {
.section_mind {
  width: 100%;
  height: auto;
  background-size: cover;
  padding: 10% 0 15%;
}

.section_mind .info_box {
  width: 90%;
  margin: -10% auto 8%;
  padding: 9% 7% 7%;
}

.section_mind .info_box .catch {
  font-size: 20px;
  margin-right: 0px;
  margin-bottom: 3%;
  line-height: 1.4;
  display: block;
}

.section_mind .info_box .txt {
  display: block;
}


}


/* section_service */
.section_service {
  margin-top: 100px;
  text-align: center;
}

.section_service .title_box h3::before {
  background-image: url(../img/cmn/service.svg);
}

.section_service .service_list {
  margin: 30px 0 36px;
}

.section_service .service_list .box {
  width: 175px;
  margin: 0 50px;
  display: inline-block;
  vertical-align: top;
}

.section_service .service_list .box a {
  transition: .5s;
}

.section_service .service_list .box a:hover {
  opacity: 1;
}

.section_service .service_list .box .icon {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: #FFF;
  padding: 52px 0;
  margin-bottom: 20px;
  transition: .5s;
}

.section_service .service_list .box a:hover .icon {
  background: #dea398;
}

.section_service .service_list .box .icon span {
  font-size: 72px;
  transition: .5s;
}

.section_service .service_list .box a:hover .icon span::before {
  color: #FFF;
}

.section_service .service_list .box .tit {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}


@media screen and (max-width: 761px) {
.section_service {
  margin: 15% 5% 0;
}

.section_service .txt {
  text-align: left;
}

.section_service .service_list {
  margin: 5% 0 8%;
}

.section_service .service_list .box {
  width: 100%;
  margin: 0 0 3%;
  display: block;
}

.section_service .service_list .box .icon {
  width: 130px;
  height: 130px;
  padding: 36px 0;
  margin-bottom: 0px;
  margin-right: 10px;
  float: left;
}

.section_service .service_list .box .icon img {
  height: 60px;
}

.section_service .service_list .box .info_box {
  float: left;
  padding: 11px 0;
}

.section_service .service_list .box .tit {
  font-size: 14px;
  text-align: left;
}

.section_service .service_list .box .txt {
  font-size: 12px;
  line-height: 1.4;
}

}


/* section_article */
.section_article {
  margin-top: 90px;
  text-align: center;
}

.section_article.case .title_box h3::before {
  background-image: url(../img/cmn/case.svg);
}

.section_article.colum .title_box h3::before {
  background-image: url(../img/cmn/colum.svg);
}

.section_article .article_list {
  margin: 40px 0 36px;
}

.section_article .article_list .box {
  width: 334px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  text-align: left;
}

.section_article .article_list .box .ph_box {
  background: #FFF;
  border: 1px solid #a2a49e;
  padding: 7px;
  width: 100%;
  margin-bottom: 14px;
}

.section_article .article_list .box .ph {
  width: 100%;
  height: 0;
  padding-bottom: 67%;
  overflow: hidden;
}

.section_article .article_list .box .ph img {
  width: 100%;
}

.section_article .article_list .box .tit {
  font-size: 15px;
  max-height: 50px;
  overflow: hidden;
  margin: 10px 0 5px;
}

@media screen and (max-width: 761px) {
.section_article {
  margin: 15% 5% 0;
  text-align: left;
}

.section_article .article_list {
  margin: 5% 0 8%;
}

.section_article .article_list .box {
  width: 100%;
  margin: 0;
}

.section_article .article_list .box .ph_box {
  padding: 5px;
  margin-bottom: 14px;
}

.section_article .article_list .box .tit {
  font-size: 14px;
  max-height: 50px;
  margin: 5px 0 5px;
}

}


/* btn_price */
.btn_price {
  width: 655px;
  height: 94px;
  margin: 80px auto;
}

.btn_price a {
  line-height: 1.0;
  padding: 28px 0 12px;
}

.btn_price a .tit {
  display: inline-block;
  vertical-align: middle;
  font-size: 50px;
  padding-left: 56px;
  background: url(../img/cmn/price.svg) 0 3px no-repeat;
  background-size: 40px auto;
  margin-right: 10px;
}

.btn_price a small {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 16px;
}

@media screen and (max-width: 761px) {
.btn_price {
  width: 90%;
  height: 76px;
  margin: 15% auto;
}

.btn_price a {
  line-height: 1.0;
  padding: 28px 0 12px;
}

.btn_price a .tit {
  font-size: 36px;
  padding-left: 36px;
  background: url(../img/cmn/price.svg) 0 2px no-repeat;
  background-size: 30px auto;
  margin-right: 10px;
}

.btn_price a small {
  font-size: 12px;
  padding-bottom: 15px;
}

}



/* section_access */
.section_access {
  text-align: center;
  position: relative;
}

.section_access .inner {
  top: 0;
  left: 50%;
  margin-left: -600px;
  width: 360px;
  background: #FFF;
  padding: 45px 0 50px;
  position: absolute;
}

.section_access .title_box h3 {
  font-size: 60px;
  padding-top: 60px;
}

.section_access .title_box h3::before {
  background-image: url(../img/cmn/access.svg);
  background-size: auto 32px;
  height: 32px;
}

.section_access .btn_more {
  margin-top: 20px;
}

.section_access .btn_more a {
  background: #f5edec;
}

.section_access .map_box {
  padding-top: 50px;
  height: 488px;
}

.section_access .map_box iframe {
  width: 100%;
  height: 438px;
  border: none;
}


@media screen and (max-width: 761px) {
.section_access {
  margin: 10% 0;
}

.section_access .inner {
  position: relative;
  top: 0;
  left: 0;
  margin:-8% auto 0;
  width: 90%;
  padding: 10% 0;
  z-index: 99;
}

.section_access .title_box h3 {
  font-size: 48px;
}

.section_access .btn_more {
  margin-top: 5%;
}

.section_access .map_box {
  padding-top: 0px;
  height: 220px;
}

.section_access .map_box iframe {
  height: 220px;
}

}
